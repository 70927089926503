<template>
  <v-card flat tile class="light-background my-3">
    <v-card-text>
      <p class="graphik-medium font-25 dark-grey--text">{{ $ml.get('fields_faq') }}</p>

      <v-expansion-panel class="hidden-shadow my-3 light-background">
        <v-expansion-panel-content
          class="light-background"
          v-for="(item, index) in faq"
          :key="index"
        >
          <template v-slot:header>
            <p class="graphik-light font-20 dark-grey--text">{{ item.question }}</p>
          </template>

          <p class="graphik-light font-14 dark-grey--text px-3" v-for="(answer, key) in item.answers" :key="key + 'p' + index">{{ answer }}</p>
        </v-expansion-panel-content>
      </v-expansion-panel>
      

      <p class="graphik-medium font-25 mb-1 dark-grey--text mt-5">{{ $ml.get('fields_doubts') }}</p>
      <p class="graphik-light font-20 dark-grey--text mb-4">{{ $ml.get('fields_doubts_send_message') }}</p>

      <v-btn 
        color="primary"
        depressed dark
        :loading="g_isLoading"
        class="normalcase graphik-bold-italic font-20 ma-0 mb-3 border-radius-5"
        @click="openChat"
      >{{ $ml.get('fields_chat') }} <v-icon dark small class="ml-2">arrow_forward</v-icon></v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'QuoterFAQ',
  computed: {
    faq () {
      if (this.$route.params.type === 'auto') {
        return [
          {
            question: this.$ml.get('faq_question_1'),
            answers: [
              this.$ml.get('faq_answer_1_1'),
              this.$ml.get('faq_answer_1_2'),
              this.$ml.get('faq_answer_1_3'),
              this.$ml.get('faq_answer_1_4')
            ]
          },
          {
            question: this.$ml.get('faq_question_2'),
            answers: [
              this.$ml.get('faq_answer_2')
            ]
          },
          {
            question: this.$ml.get('faq_question_3'),
            answers: [
              this.$ml.get('faq_answer_3_1'),
              this.$ml.get('faq_answer_3_2'),
              this.$ml.get('faq_answer_3_3'),
              this.$ml.get('faq_answer_3_4'),
              this.$ml.get('faq_answer_3_5')
            ]
          }
        ]
      } else if (this.$route.params.type === 'hogar') {
        return [
          {
            question: this.$ml.get('faq_question_4'),
            answers: [
              this.$ml.get('faq_answer_4')
            ]
          },
          {
            question: this.$ml.get('faq_question_5'),
            answers: [
              this.$ml.get('faq_answer_5')
            ]
          },
          {
            question: this.$ml.get('faq_question_6'),
            answers: [
              this.$ml.get('faq_answer_6')
            ]
          }
        ]
      } else {
        return []
      }
    }
  },
  methods: {
    openChat () {
      if (process.env.NODE_ENV !== 'production') {
        return
      }

      this.$analytics('quoter_send_messenger')
      window.FB.CustomerChat.showDialog()
    }
  }
}
</script>